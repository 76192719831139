
import { Options, Vue } from "vue-class-component";
import CallToActionCaseStudy from "@/components/public/case-studies/CallToActionCaseStudy.vue";
import { STATIC_ASSET_IMAGES_BASE_URL } from "@/commons";
import ThothButton from "@/components/common/ThothButton.vue";
import { ButtonSize, ButtonEmphasis } from "@/components/common/ThothButton.vue";

@Options({
  components: {
    CallToActionCaseStudy,
    ThothButton
  },
})
export default class NeuripsCaseStudy extends Vue {
  images = {
    background: STATIC_ASSET_IMAGES_BASE_URL + "/case-study-cta-background.png",
    speakerPortraits: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-speaker-portraits.jpg",
    openModels: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-open-models.png",
    vision: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-vision.png",
    scalingGpus: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-scaling-gpus.png",
    postTransformers: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-post-transformers.png",
    syndata: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-syndata.png",
    smolModels: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-smol-models.png",
    agents: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-agents.png",
    startupIdeas: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-startup-ideas.png",
    thothLogo: STATIC_ASSET_IMAGES_BASE_URL + "/thoth-blue-logo.png",
    lsLogo: STATIC_ASSET_IMAGES_BASE_URL + "/latent-space-logo.png",
    decibelLogo: STATIC_ASSET_IMAGES_BASE_URL + "/decibel-logo.png",
    databricksLogo: STATIC_ASSET_IMAGES_BASE_URL + "/databricks-logo.png",
    sgnLogo: STATIC_ASSET_IMAGES_BASE_URL + "/sgn-logo.png",
    logo1943: STATIC_ASSET_IMAGES_BASE_URL + "/1943-logo.png",
    cloudflareLogo: STATIC_ASSET_IMAGES_BASE_URL + "/cloudflare-logo.png",
    strongComputeLogo: STATIC_ASSET_IMAGES_BASE_URL + "/strong-compute-logo.png",
    falAILogo: STATIC_ASSET_IMAGES_BASE_URL + "/fal-ai-logo.png",
    daylightLogo: STATIC_ASSET_IMAGES_BASE_URL + "/daylight-logo.png",
  };
  links = {
    calendly: "https://calendly.com/thoth-art-jefferson/learn-about-thoth",
    hfCosmopedia: "https://huggingface.co/blog/cosmopedia",
    hfSmollm: "https://huggingface.co/blog/smollm",
    dropbox: "https://www.dropbox.com/scl/fo/8s6387ue0a5s37pwrayfi/AO5f1SDrc_D1DKHgcBomMUc?rlkey=2nxn3iu9si05s8d5zm9hpsrd3&st=ys57ohaz&dl=0",
    eventPage: "https://lu.ma/LSLIVE?tk=ZO3lLC",
    latentSpace: "https://www.latent.space/",
    thoth: "https://thoth.art"
  };
  sources = {
    conviction: "https://www.conviction.com/startups.html",
    openModels: STATIC_ASSET_IMAGES_BASE_URL + "/open-models.pdf",
    vision: STATIC_ASSET_IMAGES_BASE_URL + "/neurips-vision.pdf",
    postTransformers: STATIC_ASSET_IMAGES_BASE_URL + "/post-transformers.pdf",
    syndata: this.links.hfCosmopedia,
    smolModels: this.links.hfSmollm,
    agents: STATIC_ASSET_IMAGES_BASE_URL + "/agents.pdf",
    scalingGpus: "https://www.dwarkeshpatel.com/p/dylan-jon"
  };
  ButtonSize = ButtonSize;
  ButtonEmphasis = ButtonEmphasis;
  viewSource(pdfKey: string) {
    const pdfUrl = this.sources[pdfKey];
    window.open(pdfUrl, '_blank');
  }
  mounted() {
    // Add smooth scrolling to anchor links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault(); // Prevent the default jump behavior

        const targetId = this.getAttribute("href");
        const targetElement = document.querySelector(targetId);

        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    });
  }


}
